<template>
  <div>
    <b-list-group v-if="hersGreatBritain.length > 0">
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'form-element-herGreatBritain-' + index"
      >
        <multiselect
          :id="'form-element-herGreatBritain-' + index"
          v-model="item.id"
          :options="hersGreatBritain.map(i => i.id)"
          :custom-label="key => herLabels[key]"
          :show-labels="false"
          @select="changed"
        />
        <b-button
          v-t="'components.herGreatBritain.delete'"
          @click="deleteHerGreatBritain(index)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-spinner v-if="hersGreatBritain.length == 0" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerGreatBritain",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      hersGreatBritain: [],
      languages: [],
      herLabels: {}
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    deleteHerGreatBritain(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    }
  },
  apollo: {
    hersGreatBritain() {
      return {
        query: gql`
          query hersGreatBritain {
            hersGreatBritain {
              id
              name: uri
            }
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.hersGreatBritain = result.data.hersGreatBritain.sort((a, b) =>
            a.name.localeCompare(b.name, "sv")
          );
          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );

          this.hersGreatBritain.forEach(element => {
            this.herLabels[element.id] = element.name;
          });
        }
      };
    }
  }
};
</script>
